<template>
  <b-modal
    :id="`${layout}-layout-preview`"
    :title="$t('Layout Preview')"
    size="xl"
    scrollable
  >
    <div v-if="layout === 'product'">
      <b-row
        :class="{
          'active-display': sections[0].includes(fieldName),
          error: incompleteSection(sections[0]),
        }"
        class="section"
      >
        <b-col>
          <b-row>
            <b-col
              :class="{ focused: highlight === 'product_content_1' }"
              class="layout__item product_content_1"
              v-html="entity.content1 ? entity.content1 : $t('Content') + ' #1'"
            />
          </b-row>
          <b-row>
            <b-col
              :class="{ focused: highlight === 'product_image_2' }"
              :style="`background-image: url(${entity.image2})`"
              class="layout__item is-image product_image_2"
            >
              <span v-if="!entity.image2"> {{ $t('Image') }} #2 </span>
            </b-col>
            <b-col
              :class="{ focused: highlight === 'product_image_3' }"
              :style="`background-image: url(${entity.image3})`"
              class="layout__item is-image product_image_3"
            >
              <span v-if="!entity.image3"> {{ $t('Image') }} #3 </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              :class="{ focused: highlight === 'product_content_2' }"
              class="layout__item product_content_2"
              v-html="entity.content2 ? entity.content2 : $t('Content') + ' #2'"
            />
          </b-row>
        </b-col>

        <b-col>
          <b-row>
            <b-col
              :class="{ focused: highlight === 'product_image_1' }"
              :style="`background-image: url(${entity.image1})`"
              class="layout__item is-image double-height product_image_1"
            >
              <span v-if="!entity.image1"> {{ $t('Image') }} #1 </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              :class="{ focused: highlight === 'product_image_4' }"
              :style="`background-image: url(${entity.image4})`"
              class="layout__item is-image double-height product_image_4"
            >
              <span v-if="!entity.image4"> {{ $t('Image') }} #4 </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row
        :class="{
          'active-display': sections[1].includes(fieldName),
          error: incompleteSection(sections[1]),
        }"
        class="section"
      >
        <b-col
          :class="{ focused: highlight === 'product_image_5' }"
          :style="`background-image: url(${entity.image5})`"
          class="layout__item is-image double-height product_image_5"
        >
          <span v-if="!entity.image5"> {{ $t('Image') }} #5 </span>
        </b-col>
      </b-row>

      <b-row
        :class="{
          'active-display': sections[2].includes(fieldName),
          error: incompleteSection(sections[2]),
        }"
        class="section"
      >
        <b-col>
          <b-row>
            <b-col
              :class="{ focused: highlight === 'product_image_6' }"
              :style="`background-image: url(${entity.image6})`"
              class="layout__item is-image double-height product_image_6"
            >
              <span v-if="!entity.image6"> {{ $t('Image') }} #6 </span>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              :class="{ focused: highlight === 'product_content_4' }"
              class="layout__item product_content_4"
              v-html="entity.content4 ? entity.content4 : $t('Content') + ' #4'"
            />
          </b-row>

          <b-row>
            <b-col
              :class="{ focused: highlight === 'product_image_8' }"
              :style="`background-image: url(${entity.image8})`"
              class="layout__item is-image product_image_8"
            >
              <span v-if="!entity.image8"> {{ $t('Image') }} #8 </span>
            </b-col>
            <b-col
              :class="{ focused: highlight === 'product_image_9' }"
              :style="`background-image: url(${entity.image9})`"
              class="layout__item is-image product_image_9"
            >
              <span v-if="!entity.image9"> {{ $t('Image') }} #9 </span>
            </b-col>
          </b-row>
        </b-col>

        <b-col>
          <b-row>
            <b-col
              :class="{ focused: highlight === 'product_content_3' }"
              class="layout__item product_content_3"
              v-html="entity.content3 ? entity.content3 : $t('Content') + ' #3'"
            />
          </b-row>
          <b-row>
            <b-col
              :class="{ focused: highlight === 'product_image_7' }"
              :style="`background-image: url(${entity.image7})`"
              class="layout__item is-image triple-height product_image_7"
            >
              <span v-if="!entity.image7"> {{ $t('Image') }} #7 </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row
        :class="{
          'active-display': sections[3].includes(fieldName),
          error: incompleteSection(sections[3]),
        }"
        class="section"
      >
        <b-col
          :class="{ focused: highlight === 'product_epilogue' }"
          class="layout__item product_epilogue"
          v-html="entity.epilogue ? entity.epilogue : $t('epilogue')"
        />
      </b-row>

      <b-row
        :class="{
          'active-display': sections[4].includes(fieldName),
          error: incompleteSection(sections[4]),
        }"
        class="section"
      >
        <b-col
          :class="{ focused: highlight === 'product_general_description' }"
          class="layout__item product_general_description"
          v-html="
            entity.generalDescription
              ? entity.generalDescription
              : $t('generalDescription')
          "
        />
      </b-row>
    </div>

    <div v-else-if="layout === 'company'">
      <b-row class="section">
        <b-col>
          <b-row>
            <b-col
              :class="{ focused: highlight === 'company_featured_image' }"
              class="layout__item company_featured_image"
            >
              {{ $t('Hero Image') }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              :class="{ focused: highlight === 'company_intro' }"
              class="layout__item double-height company_intro"
            >
              {{ $t('Intro') }}
            </b-col>
          </b-row>
        </b-col>
        <b-col
          :class="{ focused: highlight === 'company_working_hours' }"
          class="layout__item double-height company_working_hours"
        >
          {{ $t('Working Hours') }}
        </b-col>
      </b-row>

      <b-row class="section">
        <b-col class="layout__item">
          {{ $t('Contact') }}
        </b-col>
        <b-col
          :class="{ focused: highlight === 'company_contact_image' }"
          class="layout__item"
        >
          {{ $t('Contact Image') }}
        </b-col>
      </b-row>

      <b-row class="section">
        <b-col
          :class="{ focused: highlight === 'company_history_image' }"
          class="layout__item company_history_image"
        >
          {{ $t('History Image') }}
        </b-col>
        <b-col
          :class="{ focused: highlight === 'company_history' }"
          class="layout__item company_history"
        >
          {{ $t('History') }}
        </b-col>
      </b-row>

      <b-row class="section">
        <b-col
          :class="{ focused: highlight === 'company_content_3' }"
          class="layout__item company_content_3"
        >
          {{ $t('Catalogue Text') }}
        </b-col>
        <b-col
          :class="{ focused: highlight === 'company_catalogue_image' }"
          class="layout__item company_catalogue_image"
        >
          {{ $t('Catalogue Image') }}
        </b-col>
      </b-row>
      <b-row class="section">
        <b-col
          :class="{ focused: highlight === 'company_content_4' }"
          class="layout__item company_content_4"
        >
          {{ $t('Extra Text') }}
        </b-col>
        <b-col
          :class="{ focused: highlight === 'company_extra_image' }"
          class="layout__item company_extra_image"
        >
          {{ $t('Extra Image') }}
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
  },
  props: {
    layout: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      highlight: null,
      fieldName: null,
      entity: {},
    }
  },
  computed: {
    sections() {
      return [
        ['content1', 'image2', 'image3', 'content2', 'image1', 'image4'],
        ['image5'],
        ['image6', 'content4', 'image8', 'image9', 'content3', 'image7'],
        ['epilogue'],
        ['generaldescription'],
      ]
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', () => {
      this.highlight = this.$root[this.layout].highlight
      this.entity = this.$root[this.layout].item

      this.fieldName = this.highlight.split('_').slice(1).join('')
    })
  },
  methods: {
    incompleteSection(sectionFields) {
      const filled = sectionFields.filter((key) => this.entity[key])
      return filled.length && filled.length !== sectionFields.length
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables';

.section {
  margin-bottom: 8px;

  &.active-display {
    border: 3px dotted #82868b;
  }

  &.error {
    border: 3px dotted red;
  }
}

.layout__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 23vh;
  line-height: 23vh;
  text-align: center;
  color: $primary;
  margin: 10px;
  color: #666260;
  background-color: #e5e0d7;

  &.focused {
    color: white;
    // font-weight: 700;
    background-color: $primary;
  }

  &.double-height {
    min-height: 36vh;
    line-height: 36vh;
  }

  &.triple-height {
    min-height: 61vh;
    line-height: 61vh;
  }

  > p {
    margin: 0;
  }

  &.is-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.focused {
      animation-name: flash;
      animation-duration: 3000ms;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
}
</style>

<style>
@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.92;
  }
}

.layout__item > *,
.layout__item > * > * {
  color: #666260;
}

.layout__item.focused > *,
.layout__item.focused > * > * {
  color: white !important;
}
</style>
