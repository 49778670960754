<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="loaded"
    :extra-actions="extraActions"
  >
    <b-card v-if="loaded">
      <b-tabs pills>
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="accountFormFields"
            allow-autofocus
          />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="personalFormFields"
          />
        </b-tab>

        <!-- Tab: Social Links -->
        <b-tab>
          <template #title>
            <feather-icon icon="LinkIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Social Links') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="socialFormFields"
          />
        </b-tab>

        <!-- Tab: Inner Page -->
        <b-tab>
          <template #title>
            <feather-icon icon="LayoutIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Layout') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="layoutFields"
          />

          <LayoutPreview layout="company" />
        </b-tab>

        <!-- Tab: Subscription -->
        <b-tab v-if="canEditSubscription">
          <template #title>
            <feather-icon icon="SlidersIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Subscription') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="subscriptionFields"
          />
        </b-tab>

        <!-- Tab: Shipping -->
        <b-tab>
          <template #title>
            <feather-icon icon="PackageIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Shipping Options') }}</span>
          </template>
          <b-row>
            <b-col cols="12">
              <RepeaterField
                :module="config.module"
                :title="$t('Shipping Option')"
                :field="entity.companyCoverAreas"
                :form-fields="shippingOptionsFields"
              />
            </b-col>
          </b-row>
        </b-tab>
        <!-- Tab: Reviews -->
        <b-tab v-if="isAdmin">
          <template #title>
            <feather-icon
              icon="TrendingUpIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Reviews') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="reviewFields"
          />
        </b-tab>
        <!-- Tab: Badges -->
        <b-tab>
          <template #title>
            <feather-icon icon="AwardIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Badges') }}</span>
          </template>
          <b-row>
            <b-col cols="12">
              <RepeaterField
                :module="config.module"
                :title="$t('Badge')"
                :field="entity.badges"
                :form-fields="badgeFormFields"
              />
            </b-col>
          </b-row>
        </b-tab>
        <!-- Tab: Primary social media links -->
        <b-tab>
          <template #title>
            <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Contact') }}</span>
          </template>
          <b-row>
            <b-col cols="12">
              <RepeaterField
                :module="config.module"
                :title="$t('Contacts')"
                :field="entity.socialLinks"
                :form-fields="socialLinksFormFields"
              />
            </b-col>
          </b-row>
        </b-tab>
        <!-- Tab: Stores -->
        <!-- <b-tab
          v-if="entity.id > 0"
          lazy
        >
          <template #title>
            <feather-icon
              icon="CreditCardIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Stores') }}</span>
          </template>
          <CompanyStoresList
            :module="config.module"
            :parent-entity.sync="entity"
          />
        </b-tab> -->

        <!-- Tab: Payments -->
        <b-tab v-if="isAdmin && entity.id > 0" lazy>
          <template #title>
            <feather-icon
              icon="CreditCardIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Payments') }}</span>
          </template>
          <CompanyPaymentsList
            :module="config.module"
            :parent-entity.sync="entity"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BRow, BCol, BTab, BTabs, BCard } from 'bootstrap-vue'

import {
  getUserRights,
  isAdmin,
  isSeller,
  isSupplier,
  canEditSubscription,
  getSubscriptionTypes,
} from '@/auth/utils'
import { translationUtils } from '@core/mixins/ui/translations'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import RepeaterField from '@/layouts/entity/RepeaterField.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import LayoutPreview from '@/layouts/entity/LayoutPreview.vue'
// import CompanyStoresList from './CompanyStoresList.vue'
import CompanyPaymentsList from './CompanyPaymentsList.vue'

import storeModule from '../companyStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    RepeaterField,
    LayoutPreview,
    // CompanyStoresList,
    CompanyPaymentsList,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        companyCoverAreas: [],
        badges: [],
        enabled: true,
        country: { id: 263, title: 'Greece' },
        socialLinks: []
      },
      loaded: false,
      bankOptions: [],
      coverAreaOptions: [],
      municipalityOptions: [],
      countryOptions: [],
      roleOptions: [],
      shippingClassOptions: [],
      badgeOptions: [],
      userGroupOptions: [],
      socialLinksOptions: [],
    }
  },
  computed: {
    rights() {
      return getUserRights()
    },
    isAdmin() {
      return isAdmin()
    },
    isSupplier() {
      return isSupplier()
    },
    isSeller() {
      return isSeller()
    },
    entityIsSupplier() {
      return this.entity.roles && this.entity.roles.filter(role => role.id === 2).length > 0
    },
    canEditSubscription() {
      return canEditSubscription()
    },
    subscriptionTypeOptions() {
      return getSubscriptionTypes()
    },
    config() {
      return {
        store: storeModule,
        module: 'companies',
        endpoint: 'companies',
        route: 'companies',
        title: {
          single: this.$t('Company_'),
          plural: this.$t('Companies'),
        },
      }
    },
    accountFormFields() {
      return [
        {
          id: 'logo',
          type: 'cropupload',
          label: `${this.$t('Logo')} (512x512)`,
          colSize: 6,
          width: 512,
          height: 512,
          autoupload: true,
          required: true,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'email',
          type: 'email',
          label: this.$t('Email'),
          required: true,
          rules: 'email',
          colSize: 3,
        },
        {
          id: 'roles',
          type: 'selectmultiple',
          label: this.$t('role'),
          options: this.roleOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'website',
          type: 'text',
          label: this.$t('website'),
          required: false,
          rules: 'url',
          colSize: 3,
        },
        {
          id: 'cataloguePdf',
          type: 'pdf',
          autoupload: true,
          label: this.$t('Catalogue'),
          required: false,
          colSize: 3,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('Enabled'),
          colSize: 2,
        },
        {
          id: 'showAsBrand',
          type: 'checkbox',
          label: this.$t('Brand Name'),
          colSize: 2,
          dependency: (() => {
            if (this.entity.roles)
              return this.entity.roles.find((item) => item.title === 'supplier')
                ? 'roles'
                : true
            else return true
          })()        
        },
        {
          id: 'description',
          type: 'textarea',
          label: this.$t('Description'),
          required: false,
          rules: 'min:3',
          colSize: 12,
        },
      ]
    },
    personalFormFields() {
      return [
        {
          id: 'country',
          object: true,
          type: 'select',
          label: this.$t('country'),
          options: this.countryOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'coverArea',
          object: true,
          type: 'select',
          label: this.$t('State'),
          options: this.coverAreaOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'dhmos',
          object: true,
          type: 'select',
          label: this.$t('Municipality'),
          options: this.municipalityOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'perioxh',
          type: 'text',
          label: this.$t('Area'),
          required: false,
          colSize: 3,
        },
        {
          id: 'address',
          type: 'text',
          label: this.$t('addressCompany'),
          required: true,
          colSize: 6,
          style: 'margin-top: 0!important;'

        },
        {
          id: 'postalCode',
          type: 'text',
          label: this.$t('postal code'),
          required: false,
          colSize: 3,
        },
        {
          id: 'phone',
          type: 'text',
          label: this.$t('telephone'),
          rules: 'phone',
          required: true,
          colSize: 3,
        },
        {
          id: 'companyName',
          type: 'text',
          label: this.$t('company name'),
          colSize: 3,
        },
        {
          id: 'vatId',
          type: 'text',
          label: this.$t('vat'),
          required: false,
          colSize: 3,
        },
        {
          id: 'vatOffice',
          type: 'text',
          label: this.$t('vat office'),
          required: false,
          colSize: 2,
        },
        {
          id: 'iban',
          type: 'text',
          label: this.$t('iban'),
          required: false,
          colSize: 3,
        },
        {
          id: 'bank',
          object: true,
          type: 'select',
          label: this.$t('bank'),
          options: this.bankOptions,
          required: false,
          colSize: 2,
        },
        {
          id: 'acceptsInstallments',
          type: 'checkbox',
          label: this.$t('Accepts Installments'),
          colSize: 2,
        },
        {
          id: 'businessActivity',
          type: 'text',
          label: this.$t('Business Activity'),
          required: false,
          colSize: 3,
        },
        {
          id: 'map',
          type: 'area-selection-map',
          colSize: 12,
          hidden:
            this.entity.roles &&
            !(this.entity.roles.filter((role) => role.id === 3).length > 0),
        },
      ]
    },
    socialFormFields() {
      return [
        {
          id: 'google',
          type: 'text',
          label: 'Google',
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'facebook',
          type: 'text',
          label: 'Facebook',
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'instagram',
          type: 'text',
          label: 'Instagram',
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'linkedIn',
          type: 'text',
          label: 'LinkedIn',
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'twitter',
          type: 'text',
          label: 'Twitter',
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'pinterest',
          type: 'text',
          label: 'Pinterest',
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'youtubeLink',
          type: 'text',
          label: 'Youtube',
          required: false,
          rules: 'url',
          colSize: 4,
        },
      ]
    },
    reviewFields() {
      return [
        {
          id: 'googleRating',
          type: 'text',
          label: this.$t('Google Rating'),
          rules: 'positive|max_value:5',
          colSize: 4,
          readonly: !this.isAdmin,
        },
        {
          id: 'googleReviewCnt',
          type: 'text',
          label: this.$t('Google Review Count'),
          rules: 'positive',
          colSize: 4,
          readonly: !this.isAdmin,
        },
        {
          id: 'facebookLikes',
          type: 'text',
          label: this.$t('Facebook Likes'),
          rules: 'positive',
          colSize: 4,
          readonly: !this.isAdmin,
        },
      ]
    },
    layoutFields() {
      return [
        {
          id: 'featuredImg',
          type: 'cropupload',
          label: this.$t('Hero Image'),
          layoutPreview: 'company_featured_image',
          colSize: 6,
          width: 512,
          height: 512,
          autoupload: true,
          hidden: this.entityIsSupplier
        },
        {
          id: 'workingHours',
          type: 'quill',
          label: this.$t('Working Hours'),
          layoutPreview: 'company_working_hours',
          colSize: 6,
          hidden: this.entityIsSupplier
        },
        {
          id: 'content1',
          type: 'quill',
          label: this.$t('Intro'),
          layoutPreview: 'company_intro',
          colSize: 6,
        },
        {
          id: 'image1',
          type: 'cropupload',
          label: `${this.$t('Contact Image')}: (960x820)`,
          layoutPreview: 'company_contact_image',
          colSize: 6,
          width: 960,
          height: 820,
          autoupload: true,
        },
        {
          id: 'image2',
          type: 'cropupload',
          label: `${this.$t('History Image')}: (960x820)`,
          layoutPreview: 'company_history_image',
          colSize: 6,
          width: 960,
          height: 820,
          autoupload: true,
        },
        {
          id: 'content2',
          type: 'quill',
          label: `${this.$t('History')}`,
          layoutPreview: 'company_history',
          colSize: 6,
        },
        {
          id: 'content3',
          type: 'quill',
          label: `${this.$t('Catalogue Text')}`,
          layoutPreview: 'company_content_3',
          colSize: 6,
        },
        {
          id: 'image3',
          type: 'cropupload',
          label: `${this.$t('Catalogue Image')}: (960x820)`,
          layoutPreview: 'company_catalogue_image',
          colSize: 6,
          width: 960,
          height: 820,
          autoupload: true,
        },
        {
          id: 'image4',
          type: 'cropupload',
          label: `${this.$t('Extra Image')}: (960x820)`,
          layoutPreview: 'company_extra_image',
          colSize: 6,
          width: 960,
          height: 820,
          autoupload: true,
          hidden: !this.entityIsSupplier
        },
      ]
    },
    subscriptionFields() {
      return [
        {
          id: 'userGroup',
          object: true,
          type: 'select',
          label: this.$t('User Group'),
          options: this.userGroupOptions,
          required: false,
          colSize: 6,
        },
        {
          id: 'subscriptionType',
          type: 'select',
          label: this.$t('Subscription Type'),
          options: this.subscriptionTypeOptions,
          required: false,
          colSize: 6,
        },
        {
          id: 'subscriptionStartDate',
          type: 'date',
          label: this.$t('Start Date'),
          required: false,
          colSize: 4,
          refMaxDate: 'subscriptionEndDate',
        },
        {
          id: 'subscriptionEndDate',
          type: 'date',
          label: this.$t('End Date'),
          required: false,
          colSize: 4,
          refMinDate: 'subscriptionStartDate',
        },
        {
          id: 'subscriptionPrice',
          type: 'text',
          label: this.$t('Price'),
          required: false,
          rules: 'positive',
          colSize: 4,
        },
      ]
    },
    shippingOptionsFields() {
      return [
        {
          id: 'coverArea',
          object: true,
          type: 'select',
          label: this.$t('Cover Area'),
          options: this.coverAreaOptions,
          required: false,
          colSize: 2,
        },
        {
          id: 'shippingCost',
          type: 'number',
          label: this.$t('Shipping Cost'),
          required: true,
          colSize: 2,
        },
        {
          id: 'freeShippingLimit',
          type: 'number',
          label: this.$t('Free Shipping Limit'),
          required: true,
          colSize: 2,
        },
        {
          id: 'shippingClass',
          object: true,
          type: 'select',
          label: this.$t('Shipping Class'),
          options: this.shippingClassOptions,
          required: false,
          colSize: 2,
        },
        {
          id: 'totalCostPerUnit',
          type: 'number',
          label: this.$t('Total Cost Per Unit'),
          required: true,
          colSize: 2,
        },
      ]
    },
    badgeFormFields() {
      return [
        {
          id: 'badge',
          object: true,
          type: 'select',
          label: this.$t('Badge'),
          options: this.badgeOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'subtitle',
          type: 'text',
          label: this.$t('subtitle'),
          required: false,
          colSize: 3,
        },
      ]
    },
    socialLinksFormFields() {
      return [
        {
          id: 'type',
          object: true,
          type: 'select',
          label: this.$t('Type'),
          options: this.socialLinksOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('Title'),
          required: true,
          colSize: 3,
        },
        {
          id: 'link',
          rules: '',
          type: 'text',
          label: this.$t('Link/Phone'),
          required: true,
          colSize: 3,
        }
      ]
    },
    extraActions() {
      const innerPath =
        this.entity &&
        this.entity.roles &&
        this.entity.roles.filter((role) => role.title === 'seller').length > 0
          ? 'seller'
          : 'supplier'
      return [
        {
          icon: 'EyeIcon',
          title: 'View in e-shop',
          href: `${process.env.VUE_APP_SHOP_URL}${innerPath}/${this.entity.eshopSlug}`,
          variant: 'outline-secondary',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
        {
          endpoint: 'eshop/publish',
          icon: 'UploadCloudIcon',
          title: 'Publish to e-shop',
          renderCondition: {
            key: 'published',
            value: false,
          },
        },
        {
          // callback: 'updateEshop',
          endpoint: 'eshop/publish',
          icon: 'UploadCloudIcon',
          title: 'Update e-shop',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
        {
          endpoint: 'eshop/unpublish',
          icon: 'DownloadCloudIcon',
          title: 'Remove from e-shop',
          variant: 'danger',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchBanks').then((response) => {
      this.bankOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCoverAreas').then((response) => {
      this.coverAreaOptions = response.data
    })
    this.$store.dispatch('app-common/fetchMunicipalities').then((response) => {
      this.municipalityOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCountries').then((response) => {
      this.countryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchRoles').then((response) => {
      this.roleOptions = response.data
    })
    this.$store.dispatch('app-common/fetchShippingClasses').then((response) => {
      this.shippingClassOptions = response.data
    })
    this.$store.dispatch('app-common/fetchBadges').then((response) => {
      this.badgeOptions = response.data
    })
    this.$store.dispatch('app-common/fetchUserGroups').then(response => {
      this.userGroupOptions = response.data
    })
    this.$store.dispatch('app-common/fetchSocialLinkTypes').then((response) => {
      this.socialLinksOptions = response.data
    })
  },
 // methods: {
  //   extraValidation() {
  //     return ''
  //   },
  // },
}
</script>
